export default function Contact(props) {
  return (
    <div id={props.id} className="text-white bg-indigo-600 pb-16">
      <div className="uppercase underline underline-offset-4 font-light text-xl pt-8 lg:pt-16 px-8 lg:px-16">
        kontakt
      </div>
      <div className="flex justify-center max-w-4xl mx-auto py-32">
        <div className="font-normal lg:text-6xl md:text-5xl sm:text-4xl text-2xl">
          <a href="mailto:hello@webdesign4ever.com" className="hover:text-yellow-400 cursor-pointer">hello@webdesign4ever.com</a>
        </div>
      </div>
    </div>
  );
}
