import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import './hero.css'

const navigation = [
  { name: 'exempeljobb', href: '#work' },
  { name: 'tjänster', href: '#services' },
  { name: 'kontakt', href: '#contact' },
]

export default function Hero() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-gray-900">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1"></div>

          {/* Mobile: Hambuger menu button */}
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Desktop: Navigation menu */}
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} style={{ fontWeight: 'lighter' }} className="font-normal uppercase leading-6 text-white hover:text-yellow-500">
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">web design 4 ever</span>
                <img
                  className="h-8 w-auto"
                  src="/img/logos/logo-tiny-white.svg"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-100"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block font-light hover:bg-gray-700 px-3 py-2 rounded-lg text-2xl text-white uppercase"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="relative isolate overflow-hidden pt-14">
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        {/* <img src="" alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" /> */}
        {/* <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div className="relative left-[calc(50% - 11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50% 30rem)] sm:w-[72.1875rem]" style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}}>
          </div>
        </div> */}
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-80">

          <div className="flex flex-col text-center">

            {/* Big Logo */}
            <div className="flex flex-col h-32 sm:h-44 w-auto">
              <svg id="Layer_2" className='h-full text-white' data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" style={{height: '100%'}} viewBox="0 0 1053.9207 500" fill="currentColor">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M0,40v420c0,22.0914,17.9086,40,40,40h420c22.0914,0,40-17.9086,40-40V40c0-22.0914-17.9086-40-40-40H40C17.9086,0,0,17.9086,0,40ZM340.8594,257.0459h-73.46l-92.2949,122.3096c-3.9316,5.21-9.9199,7.9541-15.9805,7.9541-4.1953,0-8.4238-1.3145-12.0312-4.0361-8.8174-6.6533-10.5713-19.1943-3.918-28.0117l74.1138-98.2158h-97.5952l103.0117-136.4072c6.6572-8.8135,19.1992-10.5635,28.0127-3.9072,8.8145,6.6562,10.5645,19.1982,3.9072,28.0127l-54.5996,72.3018h47.4473l72.7441-96.4014c6.6533-8.8184,19.1924-10.5713,28.0117-3.918,8.8174,6.6533,10.5713,19.1943,3.918,28.0117l-54.5635,72.3076h43.2764c11.0459,0,20,8.9541,20,20s-8.9541,20-20,20Z" />
                  <g>
                    <path d="M544.824,92.126h27.9209l16.6167,55.1611,18.3867-55.4336h22.7456l18.3867,55.4336,16.6167-55.1611h27.376l-31.8706,96.0205h-23.0181l-19.2041-54.8887-19.0679,54.8887h-23.0181l-31.8706-96.0205Z" />
                    <path d="M699.2674,92.126h76.6807v22.4727h-50.5303v14.4375h45.7632v20.8389h-45.7632v15.1182h51.2114v22.4727h-77.3618v-95.3398Z" />
                    <path d="M786.0227,92.126h48.896c11.9854,0,20.4297,2.9961,26.0142,8.5801,3.8135,3.8145,6.1289,8.8535,6.1289,15.3906v.2725c0,11.0322-6.2651,17.5703-14.7095,21.2471,11.5767,3.8145,18.9316,10.3516,18.9316,23.291v.2725c0,16.3438-13.3477,26.2861-36.0928,26.2861h-49.1685v-95.3398ZM828.3811,130.126c8.0356,0,12.5303-2.7246,12.5303-8.3086v-.2725c0-5.1758-4.0859-8.1719-11.9858-8.1719h-17.1611v16.7529h16.6167ZM832.1946,166.2188c8.0356,0,12.6665-3.1328,12.6665-8.7168v-.2725c0-5.1758-4.0859-8.5811-12.939-8.5811h-20.1577v17.5703h20.4302Z" />
                    <path d="M551.2254,202.126h36.7739c34.0503,0,53.7993,19.6123,53.7993,47.125v.2725c0,27.5127-20.0215,47.9424-54.3442,47.9424h-36.229v-95.3398ZM588.408,274.0391c15.7993,0,26.2866-8.7168,26.2866-24.1074v-.2725c0-15.2539-10.4873-24.1074-26.2866-24.1074h-10.7598v48.4873h10.7598Z" />
                    <path d="M651.3284,202.126h76.6807v22.4727h-50.5303v14.4375h45.7632v20.8389h-45.7632v15.1182h51.2114v22.4727h-77.3618v-95.3398Z" />
                    <path d="M730.8645,283.3008l14.71-17.5693c9.3975,7.4912,19.8853,11.4404,30.917,11.4404,7.0825,0,10.8965-2.4512,10.8965-6.5371v-.2725c0-3.9502-3.1328-6.1289-16.0718-9.126-20.2939-4.6309-35.957-10.3506-35.957-29.9639v-.2725c0-17.7061,14.0288-30.5088,36.9102-30.5088,16.208,0,28.8745,4.3584,39.2256,12.667l-13.2114,18.6592c-8.7168-6.1289-18.2505-9.3975-26.6948-9.3975-6.4014,0-9.5342,2.7236-9.5342,6.1289v.2725c0,4.3584,3.269,6.2646,16.4805,9.2617,21.9282,4.7666,35.5479,11.8486,35.5479,29.6914v.2725c0,19.4766-15.3906,31.0537-38.5444,31.0537-16.8887,0-32.9604-5.3125-44.6738-15.7998Z" />
                    <path d="M823.3406,202.126h26.5591v95.3398h-26.5591v-95.3398Z" />
                    <path d="M859.9764,250.0684v-.2725c0-27.7852,21.792-49.5771,51.3472-49.5771,16.7524,0,28.6021,5.1758,38.6807,13.8926l-15.5269,18.6592c-6.8096-5.7197-13.6196-8.9893-23.0176-8.9893-13.7563,0-24.3799,11.5771-24.3799,26.1504v.2725c0,15.3906,10.7598,26.4229,25.8779,26.4229,6.4014,0,11.3047-1.3613,15.2544-3.9492v-11.5771h-18.7954v-19.3408h43.8564v42.9033c-10.0791,8.3086-24.1074,14.71-41.4048,14.71-29.6919,0-51.8921-20.4307-51.8921-49.3047Z" />
                    <path d="M963.8924,202.126h24.6523l39.2256,50.3936v-50.3936h26.1504v95.3398h-23.1543l-40.7236-52.3008v52.3008h-26.1504v-95.3398Z" />
                    <path className="" d="M551.2254,312.126h76.6807v22.4727h-50.5303v14.4375h45.7632v20.8389h-45.7632v15.1182h51.2114v22.4727h-77.3618v-95.3398Z" />
                    <path className="" d="M630.3533,312.126h29.5552l21.6558,60.3369,21.6558-60.3369h29.0107l-38.6807,96.0205h-24.5161l-38.6807-96.0205Z" />
                    <path className="" d="M737.4016,312.126h76.6807v22.4727h-50.5303v14.4375h45.7632v20.8389h-45.7632v15.1182h51.2114v22.4727h-77.3618v-95.3398Z" />
                    <path className="" d="M824.1565,312.126h45.082c14.5737,0,24.6523,3.8135,31.0537,10.3516,5.5845,5.4473,8.4443,12.8027,8.4443,22.2002v.2725c0,14.5732-7.7632,24.2432-19.6128,29.2832l22.7456,33.2324h-30.5088l-19.2041-28.874h-11.5771v28.874h-26.4229v-95.3398ZM868.013,357.8887c8.9893,0,14.165-4.3584,14.165-11.3047v-.2715c0-7.4912-5.4482-11.3047-14.3013-11.3047h-17.2974v22.8809h17.4336Z" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div className="mb-10 flex flex-col items-center justify-center gap-x-6">
          <a href="#about-us" aria-label="Läs mer om oss">
            <svg className="w-8 h-8 text-white animate-bounce" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </a>
        </div>

      </div>
    </div>
  )
}