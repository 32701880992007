import './App.css';
import AnimatedCursor from "react-animated-cursor"

import Hero from './blocks/hero/hero';
import Message from './blocks/content/message';
import Work from './blocks/work/work';
import Services from './blocks/content/services';
import Contact from './blocks/content/contact';
import Footer from './blocks/content/footer';
// import Pricing from './blocks/content/pricing';


function App() {
  return (
    <div>
      {/* <Pricing /> */}
      <Hero />
      <Message id="about-us" />
      <Work id="work" />
      <Services id="services" />
      <Contact id="contact" />
      <Footer />
      <AnimatedCursor
        innerSize={0}
        outerSize={8}
        color='234, 179, 8'
        outerAlpha={0.8}
        innerScale={1}
        outerScale={5}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
      />
    </div>
  );
}

export default App;
