export default function Message(props) {
  return (
    <div id={props.id} className="py-24 sm:py-32 bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
        <span className="lg:text-[6rem] md:text-[5rem] text-5xl text-left text-white uppercase font-bold">
          Vi är en byrå som erbjuder prisvärd webbdesign till små och medelstora företag.
        </span>
      </div>
    </div>
  );
}
