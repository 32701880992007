import './work.css';

export default function Work(props) {
  return (
    <div id={props.id} className="bg-gray-700 overflow-hidden">
      <div className="flex flex-col lg:flex-row">
        <div className="bg-gray-700 lg:max-w-[30rem] lg:p-16 p-8">
          <div className="uppercase text-white underline underline-offset-4 font-light text-xl">exempeljobb</div>
          <div className="text-white pt-16 lg:pt-[5rem] xl:pt-40 text-6xl font-bold">ALERT EL</div>
          <div className="pt-8 text-white font-light text-xl">
            <p>Alert El är ett mindre företag som utför elinstallationer för privatpersoner och företag.</p>
            <br/>
            <p>2023 hjälpte vi att ta fram och utveckla Alert Els nya hemsida. Arbetet omfattade design av företagets nya varumärkesprofil.</p>
          </div>
        </div>
        <div className="hidden md:flex bg-pattern flex-1 justify-center items-center w-full py-8">
          <img
            src="/img/work/case-study.png"
            alt="Case study Alert El AB"
            className="lg:-ml-0 lg:w-[57rem] max-w-none md:-ml-4 md:w-[43rem] w-[30rem] h-fit"
            width={2432}
            height={1442}
          />
        </div>
        <div className="bg-pattern flex md:hidden flex-1 flex-col justify-center items-center w-full">
          <img
            src="/img/work/case-study-mobile.png"
            alt="Case study Alert El AB"
            width={374}
            height={690}
          />
          <img
            src="/img/work/social-media-mobile.png"
            alt="Case study Alert El AB social media"
            width={374}
            height={1130}
          />
        </div>
      </div>
    </div>
  );
}
